#topbar{
    background-image: url("../../public/img/fondo-top-menu.webp");
    padding: 10px 0;
    font-size: 14px;
    color: #ffffff;
}#topbar .socialIcons a{
    color: #ffffff !important;
}#header{
    position: sticky;
    top:0;
    padding: 14px 0;
    height: 80px;
    transition: all 0.5s;
    z-index: 997;
    background-image: url("../../public/img/menu-fondo-test.webp");
    box-shadow: 0px 6px 9px 0px rgb(0 0 0 / 6%);
}.navbar-brand{
    margin-right: 50px;
}.navbar-nav > .nav-item{
    margin-bottom: 3px;
}.navbar-nav > .nav-item > a{
    text-decoration: none;
    font-weight: bold;
    color: #fff;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 17px;
    padding-bottom: 10px !important;
    outline:none;
}.navbar-nav > .nav-item.active > a{
    color: #f36e03;   
    border-bottom: 1px solid #f36e03;
}.dropdown-item{
    color: #ffffff;
    margin-left: 15px;
    padding: 5px 0;
}.dropdown-menu{
    padding-left: 10px;
    padding-right: 10px;
    margin-top: -1px;
}
#navbarTogglerNovamar{
    z-index: 999;
    padding-left: 20px;
}.menuButton{
    font-size: 24px;
    color: #fff;    
}
.new-menu-nim{
    width: 100%;
    height: 160px;
    position: absolute;
    background-color: #00000099;
    opacity: 0;
    visibility: hidden;
    top:80px;
    transition: all ease-in-out 300ms;
    transform: translateY(10px);
}
#dropDownInsuranceServices{
    background: none !important;
}
.show-menu{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}
.menu-nim-item{
    color:#fff;
    /* border-right: 1px solid #f36e03; */
}
.menu-nim-item-link{
    width: 100% !important;
    color: #fff ;
    font-size: 17px;
    text-decoration: none;
    padding: 7px 10px;
    outline:none;
}
.menu-nim-item-link.active, .dropdown-item.active{
    color: #f36e03;
    border-bottom: 1px solid #f36f0398;
    background-color: transparent !important;
}
#dropDownInsuranceServicesMenu{
    visibility: hidden;
}
.nav-item > .nav-link{
    padding:0;
}
/* ---------------------------------------------------------- menu mobile version ---------------------------- */
.menu-mobile-version{
    position: fixed;
    width: 400px;
    z-index: 1000;
    height: 100vh;
    bottom: 0;
    background-image: url("../../public/img/menu-fondo-test.webp");
    visibility: hidden;
    transform: translateX(-100%);
    transition: all 300ms ease-in-out;
    overflow: hidden;
    
}
.menu-mobile-version.active{
    transform: translateX(0);
}
.menu-mobile-title{
    position: relative;
}
.menu-mobile-title > a{
    margin:0;
}
.menu-mobile-socialIcons{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
}
.menu-mobile-socialIcons > a > i{
    font-size: 25px;
    color: #ffffff;
}
.menu-mobile-content-links{
    height: auto;
    overflow: hidden;
}
.content-btn-close{
    width: 40px;
    height: 40px;
    position: absolute;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}
.content-btn-close > #btn-close{
    font-size: 24px;
}

@media (max-width: 1200px) {
    
    #dropDownInsuranceServicesMenu{
        visibility: visible;
    }
    .menu-mobile-version{
        visibility: visible;
    }
    .navbar-nav > .nav-item{
        margin: 5px 0 !important;
        padding: 2px;
    }
    .nav-item > i, .nav-item > span{
        color:#ffffff;
    }
}

/* media querys para evitar hover en dispositivos moviles */
@media(min-width:1200px){
    .navbar-nav > .nav-item > a:hover{
        border-bottom: 1px solid #f36e03;
        transition: 0.2s;
    }
    .dropdown-item:hover{
        border-bottom: 3px solid #f36e03;
        transition: 0.2s;
    }
    .new-menu-nim:hover{
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
    .menu-nim-item-link:hover{
        color: #f36e03;
        border-bottom: 1px solid #f36f0398;
    }
}
@media(max-width: 767px){
    .menu-mobile-version{
        width: 100%;
    }
}